@font-face {
	font-family: 'Recoleta-Alt';
	src: url('../../font/Recoleta\ Alt\ Bold.otf') format('opentype');
}

.last-img {
	display: block;
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	max-width: 100vw;
}

.parallax-img-cont {
	height: 450px;
}

.afterHeroContainer {
	position: relative;
	width: 100%;
	height: 100vh;
	margin-bottom: 200px;
}

.afterHeroHeading {
	position: relative;
	width: 100%;
	height: 163px;
	max-width: 576px;
	margin: 50px auto;
}

.afterHeroBgHeading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	font-family: 'Recoleta-Alt';
	font-style: normal;
	font-weight: bold;
	font-size: 120px;
	line-height: 135%;
	color: rgba(42, 117, 96, 0.1);
}

.afterHeroMainHeading {
	position: absolute;
	top: 40%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -10%);
	font-family: 'Recoleta';
	font-style: normal;
	font-weight: normal;
	font-size: 48px;
	line-height: 65px;
	color: #2a7560;
	z-index: 10;
	text-align: center;
}

.afterHeroImg {
	width: 20vw;
}

.afterHeroImg1 {
	position: absolute;
	top: 2vw;
	left: 15vw;
}

.afterHeroPara1 {
	position: absolute;
	font-family: 'Recoleta';
	font-size: 32px;
	line-height: 150%;
	color: #333333;
	max-width: 580px;
	width: 70vw;
	left: 30vw;
	top: 20vw;
}

.afterHeroPara3 {
	position: absolute;
	font-family: 'Recoleta';
	font-size: 32px;
	line-height: 150%;
	color: #333333;
	max-width: 580px;
	width: 70vw;
	left: 30vw;
	top: 43vw;
}

.afterHeroPara2 {
	position: absolute;
	font-family: 'Recoleta';
	font-size: 32px;
	line-height: 150%;
	color: #333333;
	max-width: 580px;
	width: 70vw;
	text-align: end;
	top: 33vw;
	right: 30vw;
}

.afterHeroImg2 {
	position: absolute;
	top: 15vw;
	left: calc(35vw + 580px);
	transform: translateY(-50%);
}

.afterHeroImg5 {
	position: absolute;
	right: 25vw;
	top: 80vh;
}

.afterHeroImg4 {
	position: absolute;
	right: 60vw;
	top: 85vh;
}

.afterHeroImg3 {
	position: absolute;
	top: 25vw;
	left: 10vw;
	max-width: 400px;
}

.greenColor {
	color: #2a7560;
	font-weight: bold;
}

#cardsHeading2-cont {
	position: relative;
	width: 100%;
	height: 170px;
}

.cardsHeading2 {
	font-family: 'Inter';
	font-size: 6vw;
	line-height: 39px;
	text-align: center;
	color: #000000;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	text-align: center;
	transform: translate(-50%, -10%);
	z-index: 1000;
}

.cardsHeading2bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	font-family: 'Recoleta-Alt';
	font-weight: bold;
	font-size: 17.8vw;
	line-height: 163px;
	color: rgba(51, 51, 51, 0.1);
}

.cardsPara {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 34px;
	color: #4f4f4f;
}

.blogsCont {
	position: relative;
	box-sizing: border-box;
	padding: 70px 0;
	width: 100%;
	background: #F6F6F0;
}

#blogCircle {
	position: absolute;
	top: 80%;
	left: 50vw;
	transform: translateX(-50%);
	z-index: 1;
}

.blogCardCont {
	width: 95%;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	max-width: 1180px;
	margin-top: 50px;
	position: relative;
	left: 50vw;
	transform: translateX(-50%);
	z-index: 10;
}

.blogCard {
	margin: auto;
	max-width: 380px;
	text-decoration: none;
}

.blogCard > img {
	width: 380px;
	height: 280px;
	max-width: 95vw;
	object-fit: cover;
	border-radius: 8px;
	display: block;
	margin: auto;
	margin-bottom: 20px;
}

.blogHeading {
	font-family: 'DM Sans';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	height: 108px;
}

.blogDesc {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 150%;
	color: #828282;
}

@media screen and (max-width: 1024px) {
	.afterHeroContainer {
		height: 70vw;
	}
	.afterHeroImg1 {
		left: 20px;
	}
	.afterHeroPara2 {
		top: 40vw;
		right: 20px;
	}
	.afterHeroPara3 {
		top: 55vw;
		left: 20px;
	}
	.afterHeroImg2 {
		left: unset;
		right: 0;
	}
	.afterHeroImg5 {
		top: 60vw;
		right: 0;
	}
	.afterHeroImg4 {
		top: 60vw;
		right: 35vw;
	}
}

@media screen and (max-width: 960px) {
	.parallax-img-cont {
		height: 95vw;
		max-height: 760px;
	}
	.afterHeroContainer {
		height: 170vw;
		margin-bottom: 0px;
	}
	.afterHeroHeading {
		max-width: unset;
	}
	.afterHeroBgHeading {
		font-size: 19.5vw;
	}
	.afterHeroImg {
		width: 30vw;
	}
	.afterHeroMainHeading {
		font-size: 7.5vw;
		transform: translate(-50%, -50%);
	}
	.afterHeroImg1 {
		top: 100px;
		left: 20px;
	}
	.afterHeroPara1 {
		font-size: 20px;
		top: 65vw;
		left: 20px;
	}
	.afterHeroPara2 {
		font-size: 20px;
		top: 100vw;
		right: 20px;
	}
	.afterHeroPara3 {
		font-size: 20px;
		top: 125vw;
		left: 20px;
	}
	.afterHeroImg2 {
		top: 55vw;
		left: unset;
		right: 0;
	}
	.afterHeroImg3 {
		top: 135vw;
		left: 10px;
	}
	.afterHeroImg5 {
		top: 140vw;
		right: 0;
	}
	.afterHeroImg4 {
		top: 140vw;
		right: 35vw;
	}
	.blogHeading {
		height: fit-content;
	}
}
