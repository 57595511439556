.careers-container {
  min-height: 100vh;
  width: min(95%, 1100px);
  margin-inline: auto;
  margin-top: 200px;
  padding: 0 8px;

  h1 {
    font-family: "Recoleta";
    font-size: 5rem;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1;
  }

  p {
    font-family: "DM Sans", sans-serif;
    font-size: 1.3rem;
    margin-bottom: 3rem;
  }
}

.form {
  max-width: 500px;
  margin: 0 auto;
}

.form iframe {
  height: 1000px;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0;
  }
}
