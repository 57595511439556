@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

@font-face {
  font-family: "Recoleta Alt";
  src: url("./font/Recoleta\ Alt\ Bold.otf") format("opentype");
}

@font-face {
  font-family: "Recoleta";
  src: url("./font/Recoleta-RegularDEMO.otf") format("opentype");
}

@font-face {
  font-family: "Motherva";
  src: url("./font/Al_Motherva_Normal.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Motherva";
  src: url("./font/Al_Motherva_Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Motherva";
  src: url("./font/Al_Motherva_Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Motherva";
  src: url("./font/Al_Motherva_Normal.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Victor Serif";
  src: url("./font/VictorSerif-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Victor Serif";
  src: url("./font/VictorSerif-RegularItalic.otf") format("opentype");
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-variant-ligatures: none;
}

body,
html {
  background: #fff;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none;
}

body::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar-thumb {
  display: none;
}

.callIconFloatingBtn {
  padding: 15px;
  box-sizing: border-box;
  z-index: 10000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 4px 44px rgb(0 0 0 / 16%);
  text-decoration: none;
  color: #f8f5f0;
}

.callIconFloatingBtn2 {
  padding: 15px;
  box-sizing: border-box;
  z-index: 10000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 4px 44px rgb(0 0 0 / 16%);
  text-decoration: none;
  color: #f8f5f0;
  cursor: pointer;
}

.floatingActionBtn {
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  display: inline-flex;
  gap: 15px;
  flex-direction: row-reverse;
  z-index: 10000;
  font-family: "DM Sans";
}

.callIconFloatingBtn > img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 960px) {
  html,
  body {
    overflow-x: unset;
    height: fit-content !important;
  }
  #blogCircle {
    display: none;
  }
  .testimonialCircle {
    display: none;
  }
}
