.propertyPageMainCont {
	background: #f8f5f0;
	padding-bottom: 50px;
}

.propertyPageTopHead {
	padding-top: 30px;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 130%;
	color: #557054;
	text-align: center;
	margin-top: 20px;
}

.propertyPageTopSubHead {
	font-family: 'Vollkorn';
	font-size: 16px;
	line-height: 130%;
	letter-spacing: 0.08em;
	color: #557054;
	text-align: center;
}

.DerTitle {
	max-width: 87vw;
	margin: auto;
	margin-top: 45px;
	font-family: 'Victor Serif';
	font-size: 16vw;
	line-height: 135%;
	text-align: center;
	color: #557054;
}

.DerSubTitle {
	font-family: 'Victor Serif';
	font-style: italic;
	text-align: center;
	margin: auto;
	font-size: 18px;
	line-height: 130%;
	margin-bottom: 50px;
	max-width: 1110px;
	width: 95vw;
	color: #828282;
}

.propImgsMain {
	display: block;
	margin: auto;
	width: 42vw;
	height: 43vw;
	object-fit: cover;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
}

.secImgsCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	transform: translateY(-20vw);
}

.propImgsSec {
	display: block;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.16);
	width: 26.5vw;
	height: 36vw;
	object-fit: cover;
}

#propImgsSec1 {
	transform: translate(-5.1vw, 8.3vw);
}

#propImgsSec2 {
	transform: translateX(13.25vw);
	align-self: flex-start;
	margin-right: 8.3vw;
}

#propImgsSec3 {
	transform: translateY(13.25vw);
	width: 18.2vw;
	object-fit: cover;
}

.propertyInfoPara1Der {
	max-width: 790px;
	margin: auto;
	text-align: center;
	font-family: 'Motherva';
	font-weight: 300;
	font-size: 120px;
	line-height: 100%;
	color: #c2948b;
}

.propertyInfoDesc1Der {
	max-width: 643px;
	width: 95vw;
	margin: auto;
	margin-top: 15px;
	font-family: 'Victor Serif';
	font-style: italic;
	font-size: 24px;
	line-height: 31px;
	text-align: center;
	color: #828282;
}

.finalImgCont {
	margin-top: 100px;
	position: relative;
	padding-bottom: 22vw;
}

.last2Img1 {
	width: 51.3vw;
	height: 41.8vw;
	object-fit: cover;
	margin-left: 9.8vw;
}

.last2Img2 {
	width: 35.76vw;
	height: 41.8vw;
	object-fit: cover;
	position: absolute;
	right: 12.84vw;
	z-index: 10;
	top: 19.72vw;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.DerTitleSmall {
	margin: auto;
	font-family: 'Victor Serif';
	font-size: 280px;
	line-height: 135%;
	color: #557054;
	text-align: center;
}

.propMainTitleSizeDer {
	font-family: 'Playfair Display';
	font-size: 100px;
	line-height: 120%;
	/* color: #4f4f4f; */
	color: #c2948b;
}

.work {
	margin-left: 14.86vw;
}

.liveAndLove {
	margin-left: 23.47vw;
}

.underThe {
	margin-left: 40vw;
}

.yourselfAt {
	margin-left: 55vw;
}

.getInTouchCont {
	display: inline-flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 15px;
	cursor: pointer;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.POIDer {
	margin: auto;
	margin-top: 100px;
	max-width: 661px;
	font-family: 'Motherva';
	font-style: normal;
	font-weight: 300;
	font-size: 120px;
	line-height: 80.33%;
	text-align: center;
	/* color: #4f4f4f; */
	color: #c2948b;
	margin-bottom: 30px;
}

.iconsCont {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	max-width: 1200px;
	margin: 120px auto;
}

.iconBoxDer {
	width: 280px;
	height: 234px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #557054;
	text-align: center;
}

.iconsSVGDer > path {
	fill: #557054;
}

.iconsSVGDer > g > path {
	fill: #557054;
}

.iconsSVGDer > g {
	fill: #557054;
}

.nearPropImgs {
	border-radius: 8px;
	width: 380px;
	height: 280px;
	object-fit: cover;
	max-width: 90vw;
}

.propCarousel {
	margin-top: 40px;
}

.propMainCarousel {
	margin-left: 9vw;
	margin-right: 5vw;
	gap: 20px;
	align-items: flex-start;
}

.propCarouselTitle {
	font-family: 'Playfair Display';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	margin-top: 15px;
}

.propCarouUL {
	list-style: none;
	font-family: 'DM Sans';
	font-size: 16px;
	line-height: 150%;
	color: #828282;
}

.topC2aDer {
	width: 100%;
	background-color: #557054;
	color: white;
	padding: 15px;
	font-size: 18px;
	text-align: center;
	font-family: 'Inter';
	box-sizing: border-box;
	position: fixed;
	top: 0;
	z-index: 100;
}

.topc2a {
	cursor: pointer;
	font-weight: bold;
}

.topc2a:hover {
	text-decoration: underline;
}

.POInearImg {
	display: block;
	margin: 20px auto;
	width: 600px;
	max-width: 95vw;
}

@media screen and (max-width: 960px) {
	.propertyPageTopHead {
		font-size: 18px;
		margin-top: 50px;
	}
	.propertyPageTopSubHead {
		font-size: 12px;
	}
	.propertyInfoPara1Der {
		font-size: 60px;
	}
	.propMainTitleSizeDer {
		font-size: 52px;
	}
	.POIDer {
		font-size: 60px;
		margin-top: 40px;
	}
	.work {
		margin-left: 8.86vw;
		margin-top: 50px;
	}
	.liveAndLove {
		margin-left: 12.47vw;
	}
	.underThe {
		margin-left: 20.69vw;
	}

	.yourselfAt {
		margin-left: 25vw;
	}
	.finalImgCont {
		margin-top: 40px;
		padding-bottom: 12vw;
	}
	.propMainCarousel {
		margin-left: 5vw;
		margin-right: 3vw;
	}
	.iconBoxDer {
		height: fit-content;
	}
	.propertyInfoDesc1Der {
		font-size: 18px;
		line-height: 22px;
	}
	.iconsCont {
		margin: 0 auto;
	}
	.DerTitle {
		font-size: 22vw;
	}
	.DerTitleSmall {
		font-size: 70px;
	}
	.propImgsSec {
		width: 29vw;
		height: 39vw;
	}
	#propImgsSec2 {
	transform: translateX(10.25vw);
	align-self: flex-start;
	/* margin-right: 9.3vw; */
}
}
