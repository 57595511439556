.secCard {
	display: none;
}

#tesr {
	background: #f5f5eb;
	padding-top: 60px;
	padding-bottom: 60px;
}

.main-carousel-cont {
	overflow-x: scroll;
	scrollbar-width: thin;
	cursor: grab;
	scroll-behavior: smooth;
}

.main-carousel-cont::-webkit-scrollbar {
	display: none;
}
.main-carousel-cont::-webkit-scrollbar-thumb {
	display: none;
}

.mainCarousel {
	display: inline-flex;
	gap: 50px;
	position: relative;
	align-items: center;
	padding-right: 25px;
}

.carouselImg {
	align-self: center;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	max-height: 750px;
	max-width: 550px;
}

#img-0 {
	z-index: 10;
	align-self: center;
}

#img-1 {
	z-index: 5;
}

#img-2 {
	z-index: 1;
}

#img-3 {
	z-index: 30;
}

#img-4 {
	z-index: 1;
}

#img-5 {
	z-index: 50;
}

#img-6 {
	z-index: 60;
}

#img-7 {
	z-index: 40;
}

#img-8 {
	z-index: 90;
}

#img-9 {
	z-index: 1000;
}

#img-10 {
	z-index: 12;
}

#img-11 {
	z-index: 4;
}

#img-12 {
	z-index: 7;

}

#img-13 {
	z-index: 20;

}

#img-14 {
	z-index: 15;
}

#img-15 {
	z-index: 15;
}

#img-16 {
	z-index: 10;
}

#img-17 {
	z-index: 1;
}

.deskCard {
	width: 590px;
	margin-left: 15vw;
	z-index: 100;
}

.deskHeading2bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	font-family: 'Recoleta-Alt';
	font-style: normal;
	font-weight: bold;
	font-size: 120px;
	line-height: 163px;
	color: rgba(42, 117, 96, 0.1);
}

.deskHeading2 {
	font-family: 'Recoleta';
	font-size: 40px;
	line-height: 54px;
	color: #2a7560;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -10%);
	z-index: 5;
}

.dropdownCont {
	display: none;
}

.carouselDD {
	background: #2a7560;
	border-radius: 56px;
	padding: 10px 20px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 18px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	width: -moz-fit-content;
	width: fit-content;
	cursor: pointer;
}

.ddContMain:hover .dropdownCont {
	background: #2a7560;
	position: absolute;
	display: block;
	z-index: 1000;
	font-family: 'DM Sans';
	color: white;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.ddContMain:hover .carouselDD {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	box-sizing: border-box;
}

.ddContMain {
	width: fit-content;
}

.dropdownElem {
	padding: 10px 20px;
	box-sizing: border-box;
	word-wrap: normal;
	cursor: pointer;
	text-decoration: none !important;
	color: white;
}

.dropdownElemLast {
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.dropdownElem:hover {
	background: #2b9778;
}

@media screen and (max-width: 960px) {
	.deskCard {
		display: none;
	}
	.secCard {
		display: block;
		width: 95%;
		margin: auto;
		margin-bottom: 50px;
	}
	.carouselImg {
		max-width: 250px;
		max-height: 450px;
	}
	.cardsPara {
		font-size: 20px;
		line-height: 24px;
	}
}
