.thankyouCont {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 10vh;
	margin-bottom: 5vh;
}

.thankyouCont > img {
	max-width: 600px;
	width: 80vw;
	display: block;
	margin-top: 50px;
}

.thankyouCont > h2 {
	text-align: center;
	max-width: 950px;
	width: 90vw;
	font-family: 'DM Sans';
	margin: 50px auto;
}

.thankyouCont > a {
	text-decoration: none;
	background: #2a7560;
	border-radius: 56px;
	padding: 12px 40px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.thankyouCont > a {
		font-size: 14px;
		line-height: 18px;
		padding: 8px 20px;
	}
}
