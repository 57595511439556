.terms-and-conditions-container {
  max-width: 1110px;
  width: 95%;
  margin: auto;
  margin-top: 150px;
  font-family: "DM Sans";
  font-size: 18px;

  h1,
  h2 {
    font-family: "Recoleta";
  }
}
