.privacy-policy-container {
  width: min(95%, 1110px);
  margin-inline: auto;
  margin-top: 150px;
  margin-bottom: 10rem;
  font-family: "DM Sans";
  font-size: 18px;

  h1,
  h2 {
    font-family: "Recoleta";
  }

  h1 {
    margin-bottom: 8.8rem;
  }

  h2 {
    margin-top: 4.3rem;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    width: 90%;
    margin-inline: auto;

    li {
      margin-bottom: 1rem;

      h3 {
        margin-bottom: 0.4rem;
      }
    }
  }
}
