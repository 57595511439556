.navbar-container {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 100vw;
  max-height: 70px;
}

.nav-basket {
  padding: 8px 8px 8px 24px;
  width: 95vw;
  box-sizing: border-box;
  margin: auto;
  max-width: 1196px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.04);
  border-radius: 64px;
}

.nav-btn {
  background: #2a7560;
  border-radius: 56px;
  padding: 12px 40px;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.dropdown-cont {
  display: none;
}

.carousel-dd {
  background: #2a7560;
  border-radius: 56px;
  padding: 10px 20px;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #ffffff;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.dd-cont-main:hover .dropdown-cont {
  background: #2a7560;
  position: absolute;
  display: block;
  z-index: 1000;
  font-family: "DM Sans";
  color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.dd-cont-main:hover .carousel-dd {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
}

.dd-cont-main {
  width: fit-content;
}

.dropdown-elem {
  padding: 10px 20px;
  box-sizing: border-box;
  word-wrap: normal;
  cursor: pointer;
  text-decoration: none !important;
  color: white;
}

.dropdown-elem-last {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.dropdown-elem:hover {
  background: #2b9778;
}

@media screen and (max-width: 500px) {
  .nav-logo {
    max-width: 150px;
  }
  .navBtn {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 20px;
  }
}
