.imageTextBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 95%;
	max-width: 1180px;
	margin: auto;
	flex: 1;
	z-index: 10;
	position: relative;
}

.bgcWhite {
	background-color: #fff;
}

.textBlockImage {
	flex-direction: row-reverse;
}

.imgTextBlkImgCont {
	max-width: 580px;
	margin: auto;
}

.imgTextBlkImgCont img {
	max-width: 580px;
}

.imgTextBlkHeading {
	font-family: 'Recoleta';
	font-size: 48px;
	line-height: 65px;
	color: #2a7560;
}

.imgTextBlkDesc {
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 160%;
	color: #828282;
}

@media screen and (max-width: 960px) {
	.imageTextBlock {
		display: block;
	}
	.imgTextBlkTextCont {
		margin-top: 30px;
	}
	.imgTextBlkImgCont {
		max-width: 95vw;
	}
	.imgTextBlkImgCont img {
		max-width: 95vw;
	}
	.imgTextBlkHeading {
		font-size: 40px;
		line-height: 55px;
	}
	.imgTextBlkDesc {
		font-size: 18px;
	}
}
