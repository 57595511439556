.footerContainer {
	background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.2) 100%
		),
		#3b7fb1;
	padding: 6em 2em;
	transform: translateY(100%);
}

.animated {
	transition: all 1.2s ease-in-out;
	transform: translateY(0);
}

.footerContent {
	display: inline-flex;
	gap: 50px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.footerHeading {
	font-family: 'Recoleta-Alt';
	font-size: 3em;
	font-weight: bold;
	color: #e5e5e5;
}

.footerSubheading {
	margin-top: 10px;
	font-family: 'DM sans';
	font-size: 12px;
	color: #e5e5e5;
}

.footerC2a {
	font-family: 'Recoleta';
	font-size: 20px;
	line-height: 27px;
	text-align: right;
	color: #f5f5ed;
	text-decoration: none;
	cursor: pointer;
}

#div2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 322px;
	gap: 10px;
	margin: auto;
	text-align: end;
}

#div1 {
	margin: auto;
}

.footerImg {
	width: 10vw;
	min-width: 100px;
	margin: auto;
}

.instaFot {
	display: inline-flex;
	justify-content: end;
	align-items: center;
	gap: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.instaFot > img {
	width: 50px;
}

@media screen and (max-width: 975px) {
	#div2,
	.footerC2a {
		text-align: center;
	}
	.footerHeading {
		width: min-content;
		margin: auto;
	}
	.footerSubheading {
		text-align: center;
	}
	#div1 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.instaFot > img {
		width: 25px;
	}
}

.thankyouTemp {
	display: none;
}
