.heroSecContainer {
	position: relative;
  width: 100%;
  height: 100vh;
  /* min-height: 100vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.heroSecContainer::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 800px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  z-index: 4;
}

.heroBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 0;
}

.BuildingFront {
  position: absolute;
  top: 65vh;
  left: 0;
  width: 100vw;
  pointer-events: none;
  z-index: 3;
}

.heroText {
  position: absolute;
  top: 50vh;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.heroName {
	font-family: 'Recoleta-Alt';
	font-weight: bold;
	font-size: 104px;
	line-height: 130%;
	text-align: center;
	color: #ffffff;
	max-width: 1100px;
	margin: auto;
}

.heroDesc {
	font-family: 'DM Sans';
  font-weight: normal;
	font-size: 24px;
	line-height: 160%;
	text-align: center;
	color: #ffffff;
  margin: auto;
  margin-top: 20px;
}

@media screen and (max-width: 960px) {
  .heroName {
    font-size: 48px;
  }
  .heroDesc {
    font-size: 14px;
  }
  .heroText {
    width: 95%;
    margin: auto;
    transition-property: all;
    transition-timing-function: linear;
  }
  .wave {
    width: 50px;
  }
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}
