.modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10001;
	height: 100vh;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10001;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100vh;
}

.modal-box {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow-y: auto;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	z-index: 10002;
}

.formContainer {
	background: #f2f2f2;
	border-radius: 8px;
	padding: 24px;
	box-sizing: border-box;
	width: 90vw;
	max-width: 450px;
	overflow: auto;
	max-height: 100vh;
}

input::placeholder {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

input::-ms-input-placeholder {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

input,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 16px 20px;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	width: 100%;
	max-width: 450px;
	margin: 8px auto;
}

select {
	background: #fff url(../../imgs/akar-icons_chevron-down.png) no-repeat center right;
	background-position-x: 97%;
	color: #000;
}

input:focus,
select:focus {
	outline: none;
}

select:invalid {
	color: #bdbdbd;
}

.firstOption {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

option {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #000;
}

.btnSubmit {
	background: #2a7560;
	margin-top: 16px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	color: #ffffff;
	cursor: pointer;
}

.FormHeading {
	font-family: 'DM Sans';
	font-weight: bold;
	font-size: 20px;
	line-height: 26px;
	color: #828282;
	margin-bottom: 16px;
}

.closeModal {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	text-decoration-line: underline;
	color: #bdbdbd;
	text-align: center;
	cursor: pointer;
	margin-top: 8px;
}
