.mainCont {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 10vh;
	margin-bottom: 5vh;
}

.mainCont > img {
	max-width: 1100px;
	width: 80vw;
	display: block;
}

.mainCont > a {
	text-decoration: none;
	background: #2a7560;
	border-radius: 56px;
	padding: 12px 40px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.mainCont > a {
		font-size: 14px;
		line-height: 18px;
		padding: 8px 20px;
	}
}
