.testimonialContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
	max-width: 100vw;
}

.testimonailContCont {
	margin: 50px auto;
	box-sizing: border-box;
	padding: 80px 10px;
  position: relative;
}

.testimonialCircle {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(20%);
}

.carousel {
	max-width: 80vw !important;
}

.chevronBtns {
	display: block;
	cursor: pointer;
  z-index: 10;
}

.cardCont {
	max-width: 1080px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin: auto;
}

.quoteImg {
	display: block;
	box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	max-width: 380px;
}

.quoteHeading {
	font-family: 'DM Sans';
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 40px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #2a7560;
	text-align: start;
}

.quote {
	font-family: 'DM Sans';
	margin-bottom: 24px;
	font-size: 24px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #4f4f4f;
	text-align: start;
}

.quotePerson {
	font-family: 'DM Sans';
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #4f4f4f;
	text-align: start;
}

.quoteBio {
	font-family: 'DM Sans';
	font-size: 24px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #828282;
	text-align: start;
}

.mobChevronCont {
  display: none;
}

@media screen and (max-width: 960px) {
	.cardCont {
		flex-direction: column-reverse;
		padding: 0 20px;
		box-sizing: border-box;
	}
	.chevronBtns {
		display: none;
	}
	.carousel {
		max-width: 100% !important;
	}
  .testimonialContainer {
    display: block;
  }
  .mobChevronCont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .quoteHeading {
    text-align: center;
  }
  .testimonailContCont {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
